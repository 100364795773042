import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="spell-list"></a><h2>Spell List</h2>
    <a id="sorcerer-wizard-spells"></a><h3>SORCERER/WIZARD SPELLS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <h5><a id="zero"></a><a id="zero-level-sorcerer-wizard-spells"></a>0-LEVEL SORCERER/WIZARD SPELLS
(CANTRIPS)</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "85%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "15%"
          }}>School</th>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "60%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>Abjuration</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#resistance">Resistance</a>:</td>
          <td> Subject gains +1 on saving throws.</td>
        </tr>
        <tr>
          <td>Conjuration</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#acid-splash">Acid Splash</a>:</td>
          <td> Orb deals 1d3 acid damage.</td>
        </tr>
        <tr className="odd-row">
          <td>Divination</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-poison">Detect Poison</a>:</td>
          <td> Detects poison in one creature or small object.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-magic">Detect Magic</a>:</td>
          <td> Detects spells and magic items within 60 ft.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#read-magic">Read Magic</a>:</td>
          <td> Read scrolls and spellbooks.</td>
        </tr>
        <tr>
          <td>Enchantment</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#daze">Daze</a>:</td>
          <td> Humanoid creature of 4 HD or less loses next action.</td>
        </tr>
        <tr className="odd-row">
          <td>Evocation</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dancing-lights">Dancing Lights</a>:</td>
          <td> Creates torches or other lights.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#flare">Flare</a>:</td>
          <td> Dazzles one creature (&ndash;1 on attack rolls).</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#light">Light</a>:</td>
          <td> Object shines like a torch.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#ray-of-frost">Ray of Frost</a>:</td>
          <td> Ray deals 1d3 cold damage.</td>
        </tr>
        <tr className="odd-row">
          <td>Illusion</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#ghost-sound">Ghost Sound</a>:</td>
          <td> Figment sounds.</td>
        </tr>
        <tr>
          <td>Necromancy</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#disrupt-undead">Disrupt Undead</a>:</td>
          <td> Deals 1d6 damage to one undead.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#touch-of-fatigue">Touch of
Fatigue</a>:</td>
          <td> Touch attack fatigues target.</td>
        </tr>
        <tr>
          <td>Transmutation</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mage-hand">Mage Hand</a>:</td>
          <td> 5-pound telekinesis.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mending">Mending</a>:</td>
          <td> Makes minor repairs on an object.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#message">Message</a>:</td>
          <td> Whispered conversation at distance.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#open-close">Open/Close</a>:</td>
          <td> Opens or closes small or light things.</td>
        </tr>
        <tr>
          <td>Universal</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#arcane-mark">Arcane Mark</a>:</td>
          <td> Inscribes a personal rune (visible or invisible).</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#prestidigitation">Prestidigitation</a>:</td>
          <td> Performs minor tricks.</td>
        </tr>
      </tbody>
    </table>
    <a id="first"></a><a id="first-level-sorcerer-wizard-spells"></a><h5>1ST-LEVEL SORCERER/WIZARD SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "85%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "15%"
          }}>School</th>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "60%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>Abjuration</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#alarm">Alarm</a>:</td>
          <td> Wards an area for 2 hours/level.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#endure-elements">Endure
Elements</a>:</td>
          <td> Exist comfortably in hot or cold environments.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#hold-portal">Hold Portal</a>:</td>
          <td> Holds door shut.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#protection-from-chaos">Protection
from Chaos</a>/<a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#protection-from-evil">Evil</a>/<a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#protection-from-good">Good</a>/<a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#protection-from-law">Law</a>:</td>
          <td> +2 to AC and saves, counter mind control, hedge out
elementals and outsiders.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shield">Shield</a>:</td>
          <td> Invisible disc gives +4 to AC, blocks magic missiles.</td>
        </tr>
        <tr>
          <td>Conjuration</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#grease">Grease</a>:</td>
          <td> Makes 10-ft. square or one object slippery.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mage-armor">Mage Armor</a>:</td>
          <td> Gives subject +4 armor bonus.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mount">Mount</a>:</td>
          <td> Summons riding horse for 2 hours/level.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#obscuring-mist">Obscuring Mist</a>:</td>
          <td> Fog surrounds you.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-i">Summon Monster I</a>:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#unseen-servant">Unseen Servant</a>:</td>
          <td> <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#invisible">Invisible</a> force obeys your
commands.</td>
        </tr>
        <tr>
          <td>Divination</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#comprehend-languages">Comprehend
Languages</a>:</td>
          <td> You understand all spoken and written languages.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-secret-doors">Detect
Secret Doors</a>:</td>
          <td> Reveals hidden doors within 60 ft.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-undead">Detect Undead</a>:</td>
          <td> Reveals undead within 60 ft.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#identify">Identify</a><sup> M</sup>:</td>
          <td> Determines properties of magic item.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#true-strike">True Strike</a>:</td>
          <td> +20 on your next attack roll.</td>
        </tr>
        <tr className="odd-row">
          <td>Enchantment</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#charm-person">Charm Person</a>:</td>
          <td> Makes one person your friend.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#hypnotism">Hypnotism</a>:</td>
          <td> Fascinates 2d4 HD of creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sleep">Sleep</a>:</td>
          <td> Puts 4 HD of creatures into magical slumber.</td>
        </tr>
        <tr>
          <td>Evocation</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#burning-hands">Burning Hands</a>:</td>
          <td> 1d4/level fire damage (max 5d4).</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#floating-disk">Floating Disk</a>:</td>
          <td> Creates 3-ft.-diameter horizontal disk that holds 100
lb./level.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-missile">Magic Missile</a>:</td>
          <td> 1d4+1 damage; +1 missile per two levels above 1st (max
5).</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shocking-grasp">Shocking Grasp</a>:</td>
          <td> Touch delivers 1d6/level electricity damage (max 5d6).</td>
        </tr>
        <tr>
          <td>Illusion</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#color-spray">Color Spray</a>:</td>
          <td> Knocks <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#unconscious">unconscious</a>, <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#blinded">blinds</a>, and/or <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#stunned">stuns</a> 1d6 weak creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#disguise-self">Disguise Self</a>:</td>
          <td> Changes your appearance.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-aura">Magic Aura</a>:</td>
          <td> Alters object&rsquo;s magic aura.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#silent-image">Silent Image</a>:</td>
          <td> Creates minor illusion of your design.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#ventriloquism">Ventriloquism</a>:</td>
          <td> Throws voice for 1 min./level.</td>
        </tr>
        <tr className="odd-row">
          <td>Necromancy</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cause-fear">Cause Fear</a>:</td>
          <td> One creature of 5 HD or less flees for 1d4 rounds.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#chill-touch">Chill Touch</a>:</td>
          <td> One touch/level deals 1d6 damage and possibly 1 Str
damage.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#ray-of-enfeeblement">Ray of
Enfeeblement</a>:</td>
          <td> Ray deals 1d6 +1 per two levels Str damage.</td>
        </tr>
        <tr>
          <td>Transmutation</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animate-rope">Animate Rope</a>:</td>
          <td> Makes a rope move at your command.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#enlarge-person">Enlarge Person</a>:</td>
          <td> Humanoid creature doubles in size.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#erase">Erase</a>:</td>
          <td> Mundane or magical writing vanishes.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#expeditious-retreat">Expeditious
Retreat</a>:</td>
          <td> Your speed increases by 30 ft.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#feather-fall">Feather Fall</a>:</td>
          <td> Objects or creatures fall slowly.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#jump">Jump</a>:</td>
          <td> Subject gets bonus on Jump checks.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-weapon">Magic Weapon</a>:</td>
          <td> Weapon gains +1 bonus.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#reduce-person">Reduce Person</a>:</td>
          <td> Humanoid creature halves in size.</td>
        </tr>
      </tbody>
    </table>
    <h5><a id="second"></a><a id="second-level-sorcerer-wizard-spells"></a>2ND-LEVEL SORCERER/WIZARD SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "85%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "15%"
          }}>School</th>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "60%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>Abjuration</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#arcane-lock">Arcane Lock</a><sup>
M</sup>:</td>
          <td> Magically locks a portal or chest.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#obscure-object">Obscure Object</a>:</td>
          <td> Masks object against scrying.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#protection-from-arrows">Protection
from Arrows</a>:</td>
          <td> Subject immune to most ranged attacks.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#resist-energy">Resist Energy</a>:</td>
          <td> Ignores first 10 (or more) points of damage/attack
from specified energy type.</td>
        </tr>
        <tr className="odd-row">
          <td>Conjuration</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#acid-arrow">Acid Arrow</a>:</td>
          <td> Ranged touch attack; 2d4 damage for 1 round +1
round/three levels.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#fog-cloud">Fog Cloud</a>:</td>
          <td> Fog obscures vision.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#glitterdust">Glitterdust</a>:</td>
          <td> <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#blinded">Blinds</a> creatures, outlines <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#invisible">invisible</a> creatures.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-ii">Summon Monster
II</a>:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-swarm">Summon Swarm</a>:</td>
          <td> Summons swarm of bats, rats, or spiders.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#web">Web</a>:</td>
          <td> Fills 20-ft.-radius spread with sticky spiderwebs.</td>
        </tr>
        <tr className="odd-row">
          <td>Divination</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-thoughts">Detect
Thoughts</a>:</td>
          <td> Allows &ldquo;listening&rdquo; to surface thoughts.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#locate-object">Locate Object</a>:</td>
          <td> Senses direction toward object (specific or type).</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#see-invisibility">See Invisibility</a>:</td>
          <td> Reveals <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#invisible">invisible</a> creatures or objects.</td>
        </tr>
        <tr>
          <td>Enchantment</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#daze-monster">Daze Monster</a>:</td>
          <td> Living creature of 6 HD or less loses next action.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#hideous-laughter">Hideous
Laughter</a>:</td>
          <td> Subject loses actions for 1 round/level.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#touch-of-idiocy">Touch of
Idiocy</a>:</td>
          <td> Subject takes 1d6 points of Int, Wis, and Cha damage.</td>
        </tr>
        <tr className="odd-row">
          <td>Evocation</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#continual-flame">Continual Flame</a><sup>
M</sup>:</td>
          <td> Makes a permanent, heatless torch.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#darkness">Darkness</a>:</td>
          <td> 20-ft. radius of supernatural shadow.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#flaming-sphere">Flaming Sphere</a>:</td>
          <td> Creates rolling ball of fire, 2d6 damage, lasts 1
round/level.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#gust-of-wind">Gust of Wind</a>:</td>
          <td> Blows away or knocks down smaller creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#scorching-ray">Scorching Ray</a>:</td>
          <td> Ranged touch attack deals 4d6 fire damage, +1 ray/four
levels (max 3).</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shatter">Shatter</a>:</td>
          <td> Sonic vibration damages objects or crystalline
creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>Illusion</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#blur">Blur</a>:</td>
          <td> Attacks miss subject 20% of the time.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#hypnotic-pattern">Hypnotic
Pattern</a>:</td>
          <td> Fascinates (2d4 + level) HD of creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#invisibility">Invisibility</a>:</td>
          <td> Subject is <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#invisible">invisible</a> for 1 min./level or
until it attacks.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-mouth">Magic Mouth</a><sup>
M</sup>:</td>
          <td> Speaks once when triggered.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#minor-image">Minor Image</a>:</td>
          <td> As silent image, plus some sound.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mirror-image">Mirror Image</a>:</td>
          <td> Creates decoy duplicates of you (1d4 +1 per three
levels, max 8).</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#misdirection">Misdirection</a>:</td>
          <td> Misleads divinations for one creature or object.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#phantom-trap">Phantom Trap</a><sup>
M</sup>:</td>
          <td> Makes item seem trapped.</td>
        </tr>
        <tr className="odd-row">
          <td>Necromancy</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#blindness/deafness">Blindness/Deafness</a>:</td>
          <td> Makes subject <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#blinded">blinded</a> or <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#deafened">deafened</a>.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#command-undead">Command Undead</a>:</td>
          <td> Undead creature obeys your commands.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#false-life">False Life</a>:</td>
          <td> Gain 1d10 temporary hp +1/level (max +10).</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#ghoul-touch">Ghoul Touch</a>:</td>
          <td> Paralyzes one subject, which exudes stench that makes
those nearby <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#sickened">sickened</a>.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#scare">Scare</a>:</td>
          <td> Panics creatures of less than 6 HD.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#spectral-hand">Spectral Hand</a>:</td>
          <td> Creates disembodied glowing hand to deliver touch
attacks.</td>
        </tr>
        <tr className="odd-row">
          <td>Transmutation</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#alter-self">Alter Self</a>:</td>
          <td> Assume form of a similar creature.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#bears-endurance">Bear&rsquo;s
Endurance</a>:</td>
          <td> Subject gains +4 to Con for 1 min./level.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#bulls-strength">Bull&rsquo;s
Strength</a>:</td>
          <td> Subject gains +4 to Str for 1 min./level.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cats-grace">Cat&rsquo;s Grace</a>:</td>
          <td> Subject gains +4 to Dex for 1 min./level.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#darkvision">Darkvision</a>:</td>
          <td> See 60 ft. in total darkness.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#eagles-splendor">Eagle&rsquo;s
Splendor</a>:</td>
          <td> Subject gains +4 to Cha for 1 min./level.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#foxs-cunning">Fox&rsquo;s
Cunning</a>:</td>
          <td> Subject gains +4 Int for 1 min./level.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#knock">Knock</a>:</td>
          <td> Opens locked or magically sealed door.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#levitate">Levitate</a>:</td>
          <td> Subject moves up and down at your direction.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#owls-wisdom">Owl&rsquo;s Wisdom</a>:</td>
          <td> Subject gains +4 to Wis for 1 min./level.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#pyrotechnics">Pyrotechnics</a>:</td>
          <td> Turns fire into <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#blinded">blinding</a> light or choking smoke.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#rope-trick">Rope Trick</a>:</td>
          <td> As many as eight creatures hide in extradimensional
space.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#spider-climb">Spider Climb</a>:</td>
          <td> Grants ability to walk on walls and ceilings.</td>
        </tr>
        <tr>
          <td className="last-row">&nbsp;</td>
          <td className="last-row"><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#whispering-wind">Whispering Wind</a>:</td>
          <td className="last-row"> Sends a short message 1 mile/level.</td>
        </tr>
      </tbody>
    </table>
    <h5><a id="third"></a><a id="third-level-sorcerer-wizard-spells"></a>3RD-LEVEL SORCERER/WIZARD SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "85%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "15%"
          }}>School</th>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "60%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>Abjuration</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dispel-magic">Dispel Magic</a>:</td>
          <td> Cancels magical spells and effects.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#explosive-runes">Explosive
Runes</a>:</td>
          <td> Deals 6d6 damage when read.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-circle-against-chaos">Magic
Circle against Chaos</a>/<a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-circle-against-evil">Evil</a>/<a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-circle-against-good">Good</a>/<a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-circle-against-law">Law</a>:</td>
          <td> As protection spells, but 10-ft. radius and 10
min./level.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#nondetection">Nondetection</a><sup>
M</sup>:</td>
          <td> Hides subject from divination, scrying.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#protection-from-energy">Protection
from Energy</a>:</td>
          <td> Absorb 12 points/level of damage from one kind of
energy.</td>
        </tr>
        <tr>
          <td>Conjuration</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#phantom-steed">Phantom Steed</a>:</td>
          <td> Magic horse appears for 1 hour/level.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sepia-snake-sigil">Sepia Snake
Sigil</a><sup> M</sup>:</td>
          <td> Creates text symbol that immobilizes reader.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sleet-storm">Sleet Storm</a>:</td>
          <td> Hampers vision and movement.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#stinking-cloud">Stinking Cloud</a>:</td>
          <td> Nauseating vapors, 1 round/level.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-iii">Summon
Monster III</a>:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr className="odd-row">
          <td>Divination</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#arcane-sight">Arcane Sight</a>:</td>
          <td> Magical auras become visible to you.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#clairaudience/clairvoyance">Clairaudience/Clairvoyance</a>:</td>
          <td> Hear or see at a distance for 1 min./level.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#tongues">Tongues</a>:</td>
          <td> Speak any language.</td>
        </tr>
        <tr>
          <td>Enchantment</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#deep-slumber">Deep Slumber</a>:</td>
          <td> Puts 10 HD of creatures to sleep.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#heroism">Heroism</a>:</td>
          <td> Gives +2 bonus on attack rolls, saves, skill checks.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#hold-person">Hold Person</a>:</td>
          <td> Paralyzes one humanoid for 1 round/level.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#rage">Rage</a>:</td>
          <td> Subjects gains +2 to Str and Con, +1 on Will saves,
&ndash;2
to AC.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#suggestion">Suggestion</a>:</td>
          <td> Compels subject to follow stated course of action.</td>
        </tr>
        <tr className="odd-row">
          <td>Evocation</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#daylight">Daylight</a>:</td>
          <td> 60-ft. radius of bright light.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#fireball">Fireball</a>:</td>
          <td> 1d6 damage per level, 20-ft. radius.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#lightning-bolt">Lightning Bolt</a>:</td>
          <td> Electricity deals 1d6/level damage.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#tiny-hut">Tiny Hut</a>:</td>
          <td> Creates shelter for ten creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wind-wall">Wind Wall</a>:</td>
          <td> Deflects arrows, smaller creatures, and gases.</td>
        </tr>
        <tr>
          <td>Illusion</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#displacement">Displacement</a>:</td>
          <td> Attacks miss subject 50%.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#illusory-script">Illusory
Script</a><sup> M</sup>:</td>
          <td> Only intended reader can decipher.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#invisibility-sphere">Invisibility
Sphere</a>:</td>
          <td> Makes everyone within 10 ft. <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#invisible">invisible</a>.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#major-image">Major Image</a>:</td>
          <td> As silent image, plus sound, smell and thermal effects.</td>
        </tr>
        <tr>
          <td>Necromancy</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#gentle-repose">Gentle Repose</a>:</td>
          <td> Preserves one corpse.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#halt-undead">Halt Undead</a>:</td>
          <td> Immobilizes undead for 1 round/level.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#ray-of-exhaustion">Ray of
Exhaustion</a>:</td>
          <td> Ray makes subject <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#exhausted">exhausted</a>.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#vampiric-touch">Vampiric Touch</a>:</td>
          <td> Touch deals 1d6/two levels damage; caster gains damage
as hp.</td>
        </tr>
        <tr>
          <td>Transmutation</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#blink">Blink</a>:</td>
          <td> You randomly vanish and reappear for 1 round/level.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#flame-arrow">Flame Arrow</a>:</td>
          <td> Arrows deal +1d6 fire damage.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#fly">Fly</a>:</td>
          <td> Subject flies at speed of 60 ft.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#gaseous-form">Gaseous Form</a>:</td>
          <td> Subject becomes insubstantial and can fly slowly.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#haste">Haste</a>:</td>
          <td> One creature/level moves faster, +1 on attack rolls,
AC, and Reflex saves.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#keen-edge">Keen Edge</a>:</td>
          <td> Doubles normal weapon&rsquo;s threat range.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#greater-magic-weapon">Magic
Weapon, Greater</a>:</td>
          <td> +1/four levels (max +5).</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#secret-page">Secret Page</a>:</td>
          <td> Changes one page to hide its real content.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shrink-item">Shrink Item</a>:</td>
          <td> Object shrinks to one-sixteenth size.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#slow">Slow</a>:</td>
          <td> One subject/level takes only one action/round,
&ndash;2 to
AC, &ndash;2 on attack rolls.</td>
        </tr>
        <tr>
          <td className="last-row">&nbsp;</td>
          <td className="last-row"><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#water-breathing">Water Breathing</a>:</td>
          <td className="last-row"> Subjects can breathe underwater.</td>
        </tr>
      </tbody>
    </table>
    <h5><a id="fourth"></a><a id="fourth-level-sorcerer-wizard-spells"></a>4TH-LEVEL SORCERER/WIZARD SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "85%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "15%"
          }}>School</th>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "60%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>Abjuration</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dimensional-anchor">Dimensional
Anchor</a>:</td>
          <td> Bars extradimensional movement.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#fire-trap">Fire Trap</a><sup> M</sup>:</td>
          <td> Opened object deals 1d4 damage +1/level.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#lesser-globe-of-invulnerability">Globe of
Invulnerability, Lesser</a>:</td>
          <td> Stops 1st- through 3rd-level spell effects.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#remove-curse">Remove Curse</a>:</td>
          <td> Frees object or person from curse.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#stoneskin">Stoneskin</a><sup> M</sup>:</td>
          <td> Ignore 10 points of damage per attack.</td>
        </tr>
        <tr>
          <td>Conjuration</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#black-tentacles">Black
Tentacles</a>:</td>
          <td> Tentacles <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="specialAttacks.html#grapple">grapple</a> all within 20 ft. spread.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dimension-door">Dimension Door</a>:</td>
          <td> Teleports you short distance.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#minor-creation">Minor Creation</a>:</td>
          <td> Creates one cloth or wood object.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#secure-shelter">Secure Shelter</a>:</td>
          <td> Creates sturdy cottage.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#solid-fog">Solid Fog</a>:</td>
          <td> Blocks vision and slows movement.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-iv">Summon Monster
IV</a>:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr>
          <td>Divination</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#arcane-eye">Arcane Eye</a>:</td>
          <td> <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#invisible">Invisible</a> floating eye moves 30
ft./round.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-scrying">Detect Scrying</a>:</td>
          <td> Alerts you of magical eavesdropping.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#locate-creature">Locate
Creature</a>:</td>
          <td> Indicates direction to familiar creature.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#scrying">Scrying</a><sup> F</sup>:</td>
          <td> Spies on subject from a distance.</td>
        </tr>
        <tr>
          <td>Enchantment</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#charm-monster">Charm Monster</a>:</td>
          <td>Makes monster believe it is your ally.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#confusion">Confusion</a>:</td>
          <td> Subjects behave oddly for 1 round/level.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#crushing-despair">Crushing Despair</a>:</td>
          <td> Subjects take &ndash;2 on attack rolls, damage rolls, saves, and checks.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#lesser-geas">Geas, Lesser</a>:</td>
          <td> Commands subject of 7 HD or less.</td>
        </tr>
        <tr>
          <td>Evocation</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#fire-shield">Fire Shield</a>:</td>
          <td> Creatures attacking you take fire damage; you&rsquo;re
protected from heat or cold.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#ice-storm">Ice Storm</a>:</td>
          <td> Hail deals 5d6 damage in cylinder 40 ft. across.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#resilient-sphere">Resilient
Sphere</a>:</td>
          <td> Force globe protects but traps one subject.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shout">Shout</a>:</td>
          <td> Deafens all within cone and deals 5d6 sonic damage.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wall-of-fire">Wall of Fire</a>:</td>
          <td> Deals 2d4 fire damage out to 10 ft. and 1d4 out to 20
ft. Passing through wall deals 2d6 damage +1/level.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wall-of-ice">Wall of Ice</a>:</td>
          <td> Ice plane creates wall with 15 hp +1/level, or
hemisphere can trap creatures inside.</td>
        </tr>
        <tr>
          <td>Illusion</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#hallucinatory-terrain">Hallucinatory
Terrain</a>:</td>
          <td> Makes one type of terrain appear like another (field
into forest, or the like).</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#illusory-wall">Illusory Wall</a>:</td>
          <td> Wall, floor, or ceiling looks real, but anything can
pass through.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#greater-invisibility">Invisibility,
Greater</a>:</td>
          <td> As invisibility, but subject can attack and stay <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#invisible">invisible</a>.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#phantasmal-killer">Phantasmal
Killer</a>:</td>
          <td> Fearsome illusion kills subject or deals 3d6 damage.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#rainbow-pattern">Rainbow
Pattern</a>:</td>
          <td> Lights fascinate 24 HD of creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shadow-conjuration">Shadow
Conjuration</a>:</td>
          <td> Mimics conjuration below 4th level, but only 20% real.</td>
        </tr>
        <tr>
          <td>Necromancy</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animate-dead">Animate Dead</a><sup>
M</sup>:</td>
          <td> Creates undead skeletons and zombies.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#bestow-curse">Bestow Curse</a>:</td>
          <td> &ndash;6 to an ability score; &ndash;4 on attack
rolls, saves, and
checks; or 50% chance of losing each action.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#contagion">Contagion</a>:</td>
          <td> Infects subject with chosen disease.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#enervation">Enervation</a>:</td>
          <td> Subject gains 1d4 negative levels.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#fear">Fear</a>:</td>
          <td> Subjects within cone flee for 1 round/level.</td>
        </tr>
        <tr className="odd-row">
          <td>Transmutation</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#mass-enlarge-person">Enlarge
Person, Mass</a>:</td>
          <td> Enlarges several creatures.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mnemonic-enhancer">Mnemonic
Enhancer</a><sup> F</sup>:</td>
          <td> Wizard only. Prepares extra spells or retains one just
cast.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#polymorph">Polymorph</a>:</td>
          <td> Gives one willing subject a new form.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#mass-reduce-person">Reduce
Person, Mass</a>:</td>
          <td> Reduces several creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#stone-shape">Stone Shape</a>:</td>
          <td> Sculpts stone into any shape.</td>
        </tr>
      </tbody>
    </table>
    <h5><a id="fifth"></a><a id="fifth-level-sorcerer-wizard-spells"></a>5TH-LEVEL SORCERER/WIZARD SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "85%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "15%"
          }}>School</th>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "60%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>Abjuration</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#break-enchantment">Break
Enchantment</a>:</td>
          <td> Frees subjects from enchantments, alterations, curses,
and petrification.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dismissal">Dismissal</a>:</td>
          <td> Forces a creature to return to native plane.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mages-private-sanctum">Mage&rsquo;s
Private Sanctum</a>:</td>
          <td> Prevents anyone from viewing or scrying an area for 24
hours.</td>
        </tr>
        <tr>
          <td>Conjuration</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cloudkill">Cloudkill</a>:</td>
          <td> Kills 3 HD or less; 4&ndash;6 HD save or die, 6+ HD
take Con
damage.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mages-faithful-hound">Mage&rsquo;s
Faithful Hound</a>:</td>
          <td> Phantom dog can guard, attack.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#major-creation">Major Creation</a>:</td>
          <td> As minor creation, plus stone and metal.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#lesser-planar-binding">Planar
Binding, Lesser</a>:</td>
          <td> Traps extraplanar creature of 6 HD or less until it
performs a task.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#secret-chest">Secret Chest</a><sup>
F</sup>:</td>
          <td> Hides expensive chest on Ethereal Plane; you retrieve
it at will.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-v">Summon Monster V</a>:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#teleport">Teleport</a>:</td>
          <td> Instantly transports you as far as 100 miles/level.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wall-of-stone">Wall of Stone</a>:</td>
          <td> Creates a stone wall that can be shaped.</td>
        </tr>
        <tr>
          <td>Divination</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#contact-other-plane">Contact
Other Plane</a>:</td>
          <td> Lets you ask question of extraplanar entity.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#prying-eyes">Prying Eyes</a>:</td>
          <td> 1d4 +1/level floating eyes scout for you.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#telepathic-bond">Telepathic
Bond</a>:</td>
          <td> Link lets allies communicate.</td>
        </tr>
        <tr className="odd-row">
          <td>Enchantment</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dominate-person">Dominate
Person</a>:</td>
          <td> Controls humanoid telepathically.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#feeblemind">Feeblemind</a>:</td>
          <td> Subject&rsquo;s Int and Cha drop to 1.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#hold-monster">Hold Monster</a>:</td>
          <td> As hold person, but any creature.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mind-fog">Mind Fog</a>:</td>
          <td> Subjects in fog get &ndash;10 to Wis and Will checks.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#symbol-of-sleep">Symbol of Sleep</a><sup>
M</sup>:</td>
          <td> Triggered rune puts nearby creatures into catatonic
slumber.</td>
        </tr>
        <tr>
          <td>Evocation</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cone-of-cold">Cone of Cold</a>:</td>
          <td> 1d6/level cold damage.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#interposing-hand">Interposing
Hand</a>:</td>
          <td> Hand provides cover against one opponent.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sending">Sending</a>:</td>
          <td> Delivers short message anywhere, instantly.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wall-of-force">Wall of Force</a>:</td>
          <td>Wall is immune to damage.</td>
        </tr>
        <tr>
          <td>Illusion</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dream">Dream</a>:</td>
          <td> Sends message to anyone sleeping.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#false-vision">False Vision</a><sup>
M</sup>:</td>
          <td> Fools scrying with an illusion.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mirage-arcana">Mirage Arcana</a>:</td>
          <td> As hallucinatory terrain, plus structures.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#nightmare">Nightmare</a>:</td>
          <td> Sends vision dealing 1d10 damage, fatigue.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#persistent-image">Persistent
Image</a>:</td>
          <td> As major image, but no concentration required.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#seeming">Seeming</a>:</td>
          <td> Changes appearance of one person per two levels.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shadow-evocation">Shadow Evocation</a>:</td>
          <td> Mimics evocation below 5th level, but only 20% real.</td>
        </tr>
        <tr className="odd-row">
          <td>Necromancy</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#blight">Blight</a>:</td>
          <td> Withers one plant or deals 1d6/level damage to plant
creature.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-jar">Magic Jar</a><sup> F</sup>:</td>
          <td> Enables possession of another creature.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#symbol-of-pain">Symbol of Pain</a><sup>
M</sup>:</td>
          <td> Triggered rune wracks nearby creatures with pain.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#waves-of-fatigue">Waves of
Fatigue</a>:</td>
          <td> Several targets become <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#fatigued">fatigued</a>.</td>
        </tr>
        <tr className="odd-row">
          <td>Transmutation</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animal-growth">Animal Growth</a>:</td>
          <td> One animal/two levels doubles in size.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#baleful-polymorph">Baleful
Polymorph</a>:</td>
          <td> Transforms subject into harmless animal.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#fabricate">Fabricate</a><sup> M</sup>:</td>
          <td> Transforms raw materials into finished items.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#overland-flight">Overland
Flight</a>:</td>
          <td> You fly at a speed of 40 ft. and can hustle over long
distances.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#passwall">Passwall</a>:</td>
          <td> Creates passage through wood or stone wall.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#telekinesis">Telekinesis</a>:</td>
          <td> Moves object, attacks creature, or hurls object or
creature.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#transmute-mud-to-rock">Transmute
Mud to Rock</a>:</td>
          <td> Transforms two 10-ft. cubes per level.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#transmute-rock-to-mud">Transmute
Rock to Mud</a>:</td>
          <td> Transforms two 10-ft. cubes per level.</td>
        </tr>
        <tr className="odd-row">
          <td>Universal</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#permanency">Permanency</a> <sup>X</sup>:</td>
          <td>Makes certain spells permanent.</td>
        </tr>
      </tbody>
    </table>
    <h5><a id="sixth"></a><a id="sixth-level-sorcerer-wizard-spells"></a>6TH-LEVEL SORCERER/WIZARD SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "85%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "15%"
          }}>School</th>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "60%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>Abjuration</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#antimagic-field">Antimagic
Field</a>:</td>
          <td> Negates magic within 10 ft.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#greater-dispel-magic">Dispel
Magic, Greater</a>:</td>
          <td> As dispel magic, but +20 on check.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#globe-of-invulnerability">Globe
of Invulnerability</a>:</td>
          <td> As lesser globe of invulnerability, plus 4th-level
spell effects.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#guards-and-wards">Guards and
Wards</a>:</td>
          <td> Array of magic effects protect area.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#repulsion">Repulsion</a>:</td>
          <td> Creatures can&rsquo;t approach you.</td>
        </tr>
        <tr>
          <td>Conjuration</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#acid-fog">Acid Fog</a>:</td>
          <td> Fog deals acid damage.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#planar-binding">Planar Binding</a>:</td>
          <td> As lesser planar binding, but up to 12 HD.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-vi">Summon Monster
VI</a>:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wall-of-iron">Wall of Iron</a><sup>
M</sup>:</td>
          <td> 30 hp/four levels; can topple onto foes.</td>
        </tr>
        <tr>
          <td>Divination</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#analyze-dweomer">Analyze
Dweomer</a><sup> F</sup>:</td>
          <td> Reveals magical aspects of subject.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#legend-lore">Legend Lore</a><sup>
M F</sup>:</td>
          <td> Lets you learn tales about a person, place, or thing.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#true-seeing">True Seeing</a><sup>
M</sup>:</td>
          <td> Lets you see all things as they really are.</td>
        </tr>
        <tr className="odd-row">
          <td>Enchantment</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#geas/quest">Geas/Quest</a>:</td>
          <td> As lesser geas, plus it affects any creature.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#greater-heroism">Heroism,
Greater</a>:</td>
          <td> Gives +4 bonus on attack rolls, saves, skill checks;
immunity to fear; temporary hp.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#mass-suggestion">Suggestion, Mass</a>:</td>
          <td> As suggestion, plus one subject/level.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#symbol-of-persuasion">Symbol of
Persuasion</a><sup> M</sup>:</td>
          <td> Triggered rune charms nearby creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>Evocation</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#chain-lightning">Chain Lightning</a>:</td>
          <td> 1d6/level damage; 1 secondary bolt/level each deals
half damage.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#contingency">Contingency</a><sup>
F</sup>:</td>
          <td> Sets trigger condition for another spell.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#forceful-hand">Forceful Hand</a>:</td>
          <td> Hand pushes creatures away.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#freezing-sphere">Freezing
Sphere</a>:</td>
          <td> Freezes water or deals cold damage.</td>
        </tr>
        <tr className="odd-row">
          <td>Illusion</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mislead">Mislead</a>:</td>
          <td> Turns you <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#invisible">invisible</a> and creates illusory
double.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#permanent-image">Permanent
Image</a>:</td>
          <td> Includes sight, sound, and smell.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#programmed-image">Programmed
Image</a><sup> M</sup>:</td>
          <td> As major image, plus triggered by event.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shadow-walk">Shadow Walk</a>:</td>
          <td> Step into shadow to travel rapidly.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#veil">Veil</a>:</td>
          <td> Changes appearance of group of creatures.</td>
        </tr>
        <tr>
          <td>Necromancy</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#circle-of-death">Circle of Death</a><sup>
M</sup>:</td>
          <td> Kills 1d4/level HD of creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#create-undead">Create Undead</a><sup>
M</sup>:</td>
          <td> Creates ghouls, ghasts, mummies, or mohrgs.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#eyebite">Eyebite</a>:</td>
          <td> Target becomes <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#panicked">panicked</a>, <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#sickened">sickened</a>, and comatose.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#symbol-of-fear">Symbol of Fear</a><sup>
M</sup>:</td>
          <td> Triggered rune panics nearby creatures.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#undeath-to-death">Undeath to
Death</a><sup> M</sup>:</td>
          <td> Destroys 1d4/level HD of undead (max 20d4).</td>
        </tr>
        <tr className="odd-row">
          <td>Transmutation</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#mass-bears-endurance">Bear&rsquo;s
Endurance, Mass</a>:</td>
          <td> As bear&rsquo;s endurance, affects one subject/level.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#mass-bulls-strength">Bull&rsquo;s
Strength, Mass</a>:</td>
          <td> As bull&rsquo;s strength, affects one subject/ level.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#mass-cats-grace">Cat&rsquo;s
Grace, Mass</a>:</td>
          <td> As cat&rsquo;s grace, affects one subject/level.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#control-water">Control Water</a>:</td>
          <td> Raises or lowers bodies of water.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#disintegrate">Disintegrate</a>:</td>
          <td>Makes one creature or object vanish.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#mass-eagles-splendor">Eagle&rsquo;s
Splendor, Mass</a>:</td>
          <td> As eagle&rsquo;s splendor, affects one subject/level.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#flesh-to-stone">Flesh to Stone</a>:</td>
          <td> Turns subject creature into statue.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#mass-foxs-cunning">Fox&rsquo;s
Cunning, Mass</a>:</td>
          <td> As fox&rsquo;s cunning, affects one subject/ level.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mages-lucubration">Mage&rsquo;s
Lucubration</a>:</td>
          <td> Wizard only. Recalls spell of 5th level or lower.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#move-earth">Move Earth</a>:</td>
          <td> Digs trenches and build hills.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mass-owls-wisdom">Owl&rsquo;s
Wisdom, Mass</a>:</td>
          <td> As owl&rsquo;s wisdom, affects one subject/ level.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#stone-to-flesh">Stone to Flesh</a>:</td>
          <td> Restores <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#petrified">petrified</a> creature.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#transformation">Transformation</a><sup>
M</sup>:</td>
          <td> You gain combat bonuses.</td>
        </tr>
      </tbody>
    </table>
    <h5><a id="seventh"></a><a id="seventh-level-sorcerer-wizard-spells"></a>7TH-LEVEL SORCERER/WIZARD SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "85%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "15%"
          }}>School</th>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "60%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>Abjuration</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#banishment">Banishment</a>:</td>
          <td> Banishes 2 HD/level of extraplanar creatures.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sequester">Sequester</a>:</td>
          <td> Subject is <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#invisible">invisible</a> to sight and scrying;
renders creature comatose.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#spell-turning">Spell Turning</a>:</td>
          <td> Reflect 1d4+6 spell levels back at caster.</td>
        </tr>
        <tr>
          <td>Conjuration</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#instant-summons">Instant
Summons</a><sup> M</sup>:</td>
          <td> Prepared object appears in your hand.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mages-magnificent-mansion">Mage&rsquo;s
Magnificent Mansion</a><sup> F</sup>:</td>
          <td> Door leads to extradimensional mansion.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#phase-door">Phase Door</a>:</td>
          <td> Creates an <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#invisible">invisible</a> passage through wood
or stone.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#plane-shift">Plane Shift</a><sup>
F</sup>:</td>
          <td> As many as eight subjects travel to another plane.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-vii">Summon
Monster VII</a>:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#greater-teleport">Teleport,
Greater</a>:</td>
          <td> As teleport, but no range limit and no off-target
arrival.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#teleport-object">Teleport
Object</a>:</td>
          <td> As teleport, but affects a touched object.</td>
        </tr>
        <tr className="odd-row">
          <td>Divination</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#greater-arcane-sight">Arcane
Sight, Greater</a>:</td>
          <td> As arcane sight, but also reveals magic effects on
creatures and objects.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#greater-scrying">Scrying, Greater</a>:</td>
          <td> As scrying, but faster and longer.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#vision">Vision</a> <sup>M X</sup>:</td>
          <td> As legend lore, but quicker and strenuous.</td>
        </tr>
        <tr>
          <td>Enchantment</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#mass-hold-person">Hold Person,
Mass</a>:</td>
          <td> As hold person, but all within 30 ft.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#insanity">Insanity</a>:</td>
          <td> Subject suffers continuous confusion.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#power-word-blind">Power Word
Blind</a>:</td>
          <td> <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#blinded">Blinds</a> creature with 200 hp or
less.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#symbol-of-stunning">Symbol of
Stunning</a><sup> M</sup>:</td>
          <td> Triggered rune stuns nearby creatures.</td>
        </tr>
        <tr>
          <td>Evocation</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#delayed-blast-fireball">Delayed
Blast Fireball</a>:</td>
          <td> 1d6/level fire damage; you can postpone blast for 5
rounds.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#forcecage">Forcecage</a><sup> M</sup>:</td>
          <td> Cube or cage of force imprisons all inside.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#grasping-hand">Grasping Hand</a>:</td>
          <td> Hand provides cover, pushes, or <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="specialAttacks.html#grapple">grapples</a>.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mages-sword">Mage&rsquo;s Sword</a><sup>
F</sup>:</td>
          <td> Floating magic blade strikes opponents.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#prismatic-spray">Prismatic
Spray</a>:</td>
          <td> Rays hit subjects with variety of effects.</td>
        </tr>
        <tr className="odd-row">
          <td>Illusion</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#mass-invisibility">Invisibility,
Mass</a>:</td>
          <td> As invisibility, but affects all in range.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#project-image">Project Image</a>:</td>
          <td> Illusory double can talk and cast spells.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#greater-shadow-conjuration">Shadow
Conjuration, Greater</a>:</td>
          <td> As shadow conjuration, but up to 6th level and 60%
real.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#simulacrum">Simulacrum</a> <sup>M
X</sup>:</td>
          <td> Creates partially real double of a creature.</td>
        </tr>
        <tr className="odd-row">
          <td>Necromancy</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#control-undead">Control Undead</a>:</td>
          <td> Undead don&rsquo;t attack you while under your command.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#finger-of-death">Finger of
Death</a>:</td>
          <td> Kills one subject.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#symbol-of-weakness">Symbol of
Weakness</a><sup> M</sup>:</td>
          <td> Triggered rune weakens nearby creatures.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#waves-of-exhaustion">Waves of
Exhaustion</a>:</td>
          <td> Several targets become <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#exhausted">exhausted</a>.</td>
        </tr>
        <tr className="odd-row">
          <td>Transmutation</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#control-weather">Control Weather</a>:</td>
          <td> Changes weather in local area.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#ethereal-jaunt">Ethereal Jaunt</a>:</td>
          <td> You become ethereal for 1 round/level.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#reverse-gravity">Reverse
Gravity</a>:</td>
          <td> Objects and creatures fall upward.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#statue">Statue</a>:</td>
          <td> Subject can become a statue at will.</td>
        </tr>
        <tr className="odd-row">
          <td>Universal</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#limited-wish">Limited Wish</a>
            <sup>X</sup>:</td>
          <td> Alters reality&mdash;within spell limits.</td>
        </tr>
      </tbody>
    </table>
    <h5><a id="eighth"></a><a id="eighth-level-sorcerer-wizard-spells"></a>8TH-LEVEL SORCERER/WIZARD SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "85%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "15%"
          }}>School</th>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "60%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>Abjuration</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dimensional-lock">Dimensional
Lock</a>:</td>
          <td> Teleportation and interplanar travel blocked for one
day/level.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mind-blank">Mind Blank</a>:</td>
          <td> Subject is immune to mental/emotional magic and
scrying.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#prismatic-wall">Prismatic Wall</a>:</td>
          <td>Wall&rsquo;s colors have array of effects.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#protection-from-spells">Protection
from Spells</a><sup> M F</sup>:</td>
          <td> Confers +8 resistance bonus.</td>
        </tr>
        <tr className="odd-row">
          <td>Conjuration</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#incendiary-cloud">Incendiary
Cloud</a>:</td>
          <td> Cloud deals 4d6 fire damage/round.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#maze">Maze</a>:</td>
          <td> Traps subject in extradimensional maze.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#greater-planar-binding">Planar
Binding, Greater</a>:</td>
          <td> As lesser planar binding, but up to 18 HD.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-viii">Summon
Monster VIII</a>:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#trap-the-soul">Trap the Soul</a><sup>
M F</sup>:</td>
          <td> Imprisons subject within gem.</td>
        </tr>
        <tr>
          <td>Divination</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#discern-location">Discern
Location</a>:</td>
          <td> Reveals exact location of creature or object.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#moment-of-prescience">Moment
of Prescience</a>:</td>
          <td> You gain insight bonus on single attack roll, check,
or save.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#greater-prying-eyes">Prying
Eyes, Greater</a>:</td>
          <td> As prying eyes, but eyes have true seeing.</td>
        </tr>
        <tr className="odd-row">
          <td>Enchantment</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#antipathy">Antipathy</a>:</td>
          <td> Object or location affected by spell repels certain
creatures.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#binding">Binding</a><sup> M</sup>:</td>
          <td> Utilizes an array of techniques to imprison a creature.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#mass-charm-monster">Charm
Monster, Mass</a>:</td>
          <td> As charm monster, but all within 30 ft.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#demand">Demand</a>:</td>
          <td> As sending, plus you can send suggestion.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#irresistible-dance">Irresistible
Dance</a>:</td>
          <td> Forces subject to dance.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#power-word-stun">Power Word
Stun</a>:</td>
          <td> Stuns creature with 150 hp or less.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#symbol-of-insanity">Symbol of
Insanity</a><sup> M</sup>:</td>
          <td> Triggered rune renders nearby creatures insane.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sympathy">Sympathy</a><sup> F</sup>:</td>
          <td> Object or location attracts certain creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>Evocation</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#clenched-fist">Clenched Fist</a>:</td>
          <td> Large hand provides cover, pushes, or attacks your
foes.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#polar-ray">Polar Ray</a>:</td>
          <td> Ranged touch attack deals 1d6/level cold damage.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#greater-shout">Shout, Greater</a>:</td>
          <td> Devastating yell deals 10d6 sonic damage; stuns
creatures, damages objects.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sunburst">Sunburst</a>:</td>
          <td> Blinds all within 10 ft., deals 6d6 damage.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#telekinetic-sphere">Telekinetic
Sphere</a>:</td>
          <td> As resilient sphere, but you move sphere
telekinetically.</td>
        </tr>
        <tr>
          <td>Illusion</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#scintillating-pattern">Scintillating
Pattern</a>:</td>
          <td> Twisting colors confuse, stun, or render <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#unconscious">unconscious</a>.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#screen">Screen</a>:</td>
          <td> Illusion hides area from vision, scrying.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#greater-shadow-evocation">Shadow
Evocation, Greater</a>:</td>
          <td> As shadow evocation, but up to 7th level and 60% real.</td>
        </tr>
        <tr className="odd-row">
          <td>Necromancy</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#clone">Clone</a><sup> M F</sup>:</td>
          <td> Duplicate awakens when original dies.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#create-greater-undead">Create
Greater Undead</a><sup> M</sup>:</td>
          <td> Create shadows, wraiths, spectres, or devourers.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#horrid-wilting">Horrid Wilting</a>:</td>
          <td> Deals 1d6/level damage within 30 ft.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#symbol-of-death">Symbol of Death</a><sup>
M</sup>:</td>
          <td> Triggered rune slays nearby creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>Transmutation</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#iron-body">Iron Body</a>:</td>
          <td> Your body becomes living iron.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#polymorph-any-object">Polymorph
Any Object</a>:</td>
          <td> Changes any subject into anything else.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#temporal-stasis">Temporal
Stasis</a><sup> M</sup>:</td>
          <td> Puts subject into suspended animation.</td>
        </tr>
      </tbody>
    </table>
    <h5><a id="ninth"></a><a id="ninth-level-sorcerer-wizard-spells"></a>9TH-LEVEL SORCERER/WIZARD SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "85%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "15%"
          }}>School</th>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "60%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>Abjuration</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#freedom">Freedom</a>:</td>
          <td> Releases creature from imprisonment.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#imprisonment">Imprisonment</a>:</td>
          <td> Entombs subject beneath the earth.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mages-disjunction">Mage&rsquo;s
Disjunction</a>:</td>
          <td> Dispels magic, disenchants magic items.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#prismatic-sphere">Prismatic
Sphere</a>:</td>
          <td> As prismatic wall, but surrounds on all sides.</td>
        </tr>
        <tr className="odd-row">
          <td>Conjuration</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#gate">Gate</a> <sup>X</sup>:</td>
          <td> Connects two planes for travel or summoning.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#refuge">Refuge</a><sup> M</sup>:</td>
          <td> Alters item to transport its possessor to you.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-ix">Summon Monster
IX</a>:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#teleportation-circle">Teleportation
Circle</a><sup> M</sup>:</td>
          <td> Circle teleports any creature inside to designated
spot.</td>
        </tr>
        <tr className="odd-row">
          <td>Divination</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#foresight">Foresight</a>:</td>
          <td> &ldquo;Sixth sense&rdquo; warns of impending danger.</td>
        </tr>
        <tr>
          <td>Enchantment</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dominate-monster">Dominate
Monster</a>:</td>
          <td> As dominate person, but any creature.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#mass-hold-monster">Hold
Monster, Mass</a>:</td>
          <td> As hold monster, but all within 30 ft.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#power-word-kill">Power Word
Kill</a>:</td>
          <td> Kills one creature with 100 hp or less.</td>
        </tr>
        <tr className="odd-row">
          <td>Evocation</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#crushing-hand">Crushing Hand</a>:</td>
          <td> Large hand provides cover, pushes, or crushes your
foes.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#meteor-swarm">Meteor Swarm</a>:</td>
          <td> Four exploding spheres each deal 6d6 fire damage.</td>
        </tr>
        <tr className="odd-row">
          <td>Illusion</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shades">Shades</a>:</td>
          <td> As shadow conjuration, but up to 8th level and 80%
real.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#weird">Weird</a>:</td>
          <td> As phantasmal killer, but affects all within 30 ft.</td>
        </tr>
        <tr className="odd-row">
          <td>Necromancy</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#astral-projection">Astral
Projection</a><sup> M</sup>:</td>
          <td> Projects you and companions onto Astral Plane.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#energy-drain">Energy Drain</a>:</td>
          <td> Subject gains 2d4 negative levels.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#soul-bind">Soul Bind</a><sup> F</sup>:</td>
          <td> Traps newly <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#dead">dead</a> soul to prevent
resurrection.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wail-of-the-banshee">Wail of
the Banshee</a>:</td>
          <td> Kills one creature/level.</td>
        </tr>
        <tr className="odd-row">
          <td>Transmutation</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#etherealness">Etherealness</a>:</td>
          <td> Travel to Ethereal Plane with companions.</td>
        </tr>
        <tr>
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shapechange">Shapechange</a><sup>
F</sup>:</td>
          <td> Transforms you into any creature, and change forms
once per round.</td>
        </tr>
        <tr className="odd-row">
          <td>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#time-stop">Time Stop</a>:</td>
          <td> You act freely for 1d4+1 rounds.</td>
        </tr>
        <tr>
          <td className="last-row">Universal</td>
          <td className="last-row"><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wish">Wish</a>
            <sup>X</sup>:</td>
          <td className="last-row"> As limited wish, but with fewer
limits.</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      